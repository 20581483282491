<template>
  <div>
    <a-spin :spinning="false">
      <div class="bg-w pd20" style="min-height: 800px; min-width: 1000px">
        <div style="width: 800px">
          <div class="ft20 ftw500 cl-main">小程序设置</div>

          <div v-if="datas != null" class="mt20">
            <a-form-model ref="ruleForm" :model="datas" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
              <div>
                <a-form-model-item label="AppId">
                  <a-input v-model="datas.app_id" style="width: 300px" />
                </a-form-model-item>
                <a-form-model-item label="AppSecret">
                  <a-input v-model="datas.app_secret" style="width: 300px" />
                </a-form-model-item>
                <!-- <a-form-model-item label="消息通知TOKEN">
                  <a-input v-model="datas.app_token" style="width: 300px" />
                </a-form-model-item>
                <a-form-model-item label="消息通知秘钥">
                  <a-input v-model="datas.app_key" style="width: 300px" />
                </a-form-model-item>
                <a-form-model-item label="开发小程序ID">
                  <a-input v-model="datas.miniapp_id" style="width: 300px" />
                </a-form-model-item>
                <a-form-model-item label="开发小程序上传秘钥">
                  <a-textarea
                    :rows="5"
                    v-model="datas.miniapp_auth"
                    style="width: 400px"
                  />
                </a-form-model-item> -->
              </div>
            </a-form-model>
          </div>
        </div>
        <div style="width: 800px">
          <div class="ft20 ftw500 cl-main">公众号设置</div>

          <div v-if="datas != null" class="mt20">
            <a-form-model ref="ruleForm" :model="datas" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
              <div>
                <a-form-model-item label="AppId">
                  <a-input v-model="datas.mp_app_id" style="width: 300px" />
                </a-form-model-item>
                <a-form-model-item label="AppSecret">
                  <a-input v-model="datas.mp_app_secret" style="width: 300px" />
                </a-form-model-item>
                <a-form-model-item label="服务器地址(URL)">
                  {{ datas.mp_app_url }}
                </a-form-model-item>
                <a-form-model-item label="令牌(Token)">
                  {{ datas.mp_app_token }}
                </a-form-model-item>
                <!-- <a-form-model-item label="消息通知秘钥">
                  <a-input v-model="datas.app_key" style="width: 300px" />
                </a-form-model-item>
                <a-form-model-item label="开发小程序ID">
                  <a-input v-model="datas.miniapp_id" style="width: 300px" />
                </a-form-model-item>
                <a-form-model-item label="开发小程序上传秘钥">
                  <a-textarea
                    :rows="5"
                    v-model="datas.miniapp_auth"
                    style="width: 400px"
                  />
                </a-form-model-item> -->
              </div>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="saveData"> 保存设置 </a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      datas: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.$http
        .api("platform/admin/getSettingData", {
          key: "open",
        })
        .then((res) => {
          this.loading = false;
          this.datas = res.datas;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    saveData() {
      this.loading = true;
      this.$http
        .api("platform/admin/saveSettingData", {
          key: "open",
          datas: JSON.stringify(this.datas),
        })
        .then((res) => {
          this.loading = false;
          this.$message.success("保存成功");
        })
        .catch((res) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
